@import './tailwind-normalize.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves ExtraLight.otf') format('opentype');
  font-weight: 200;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Light.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Regular.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves DemiBold.otf') format('opentype');
  font-weight: 600;
}

@font-face {
  font-family: 'TTHoves';
  src: local('Poppins'), url('./fonts/TT Hoves Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Book_13.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Medium_6.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'P22Mackinac';
  src: local('Poppins'), url('./fonts/P22Mackinac-Bold_23.otf') format('opentype');
  font-weight: 700;
}
